/** @jsxRuntime classic */
/** @jsx jsx */
import { Icon } from "@zapier/zinnia-icons";
import { Button, Link, Margin, Text } from "@zapier/design-system";
import { css, jsx } from "@emotion/react";
import { useEffect } from "react";
import { BasicLayout } from "./BasicLayout";

const containerStyle = css`
  margin: 60px auto;
  max-width: 600px;
  text-align: center;
`;

// We can have fallback errors around specific parts of the app
export function ErrorFallback({ error }: any) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);

  return (
    <div css={containerStyle}>
      <Margin margin="20px">
        <Icon color="StatusError" name="alertTriangle" size="60px" />
        <Text tag="h1" type="PageHeader">
          We got an unexpected error
        </Text>
        <Text tag="p" type="ParagraphHeader1">
          Contact us at{" "}
          <Link href="mailto:partners@zapier.com">partners@zapier.com</Link>
        </Text>
        <Margin margin="40px 0">
          <Button href="/app/get-help" size="large">
            Get help
          </Button>
        </Margin>
      </Margin>
    </div>
  );
}

// If we hit the app level error boundary, at least render a navigation with the error
export function TopLevelErrorFallback({ error }: any) {
  return (
    <BasicLayout>
      <ErrorFallback error={error} />
    </BasicLayout>
  );
}

export function ErrorBoundaryHandler(error: Error) {
  throw new Error(`Unexpected error: ${error.message}`);
}
