import type { AppProps } from "next/app";
import Head from "next/head";
import React from "react";
import "@zapier/design-tokens/custom-properties.css";
import { ErrorBoundary } from "react-error-boundary";
import { TopLevelErrorFallback } from "src/components/ErrorFallback";
// Used for automatic dark mode in the /partner/embed/*/examples pages
import "@zapier/zinnia-icons/style.css";
import "src/global.css";
import { useTrackRouting } from "src/observability/tracking/useTrackRouting";

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  useTrackRouting();
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <ErrorBoundary FallbackComponent={TopLevelErrorFallback}>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  );
}
